@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  padding: 0;
  margin: 0;
  background: #f4f6f7;
  font-family: 'Roboto Condensed', BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

.center {
  width: 100%;
  text-align: center;
  margin: auto;
}

.calender-zoom {
  zoom: 1.75;
}

.content-container {
  min-height: 100vh;
  /* padding: 0 5rem 200px 5rem; */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-container {
  background: white;
  width: 80vw;
  min-height: 300px;
  height: max-content;
  border-radius: 15px;
  padding: 20px;
  z-index: 100;
  margin-top: 100px;
}

.modal-header {
  position: relative;
  font-size: 25px;
  padding-bottom: 10px;
}

.modal-header a {
  position: absolute;
  right: 0;
}

.modal-body {
  padding-top: 10px;
}

.date-picker label {
  background: #f4f6f7;
  padding: 0 10px 10px 0;
}

.modal-body .date-picker label {
  background: white;
}

@media (min-width:480px) {
  .content-container {
    padding: 30px;
  }
}



.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  padding: 0 10px;
  background: #fff;
  box-shadow: 10px 5px 10px 1px #eee;
  display: flex;
  align-items: center;
  z-index: 999
}

.menu {
  height: 100%;
  margin-left: 20px;
  line-height: 1;
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.rightMenu {
  height: 100%;
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.footer {
  width: 100%;
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  box-shadow: 10px 5px 10px 1px #eee;
}

.footer p {
  color: #888;
}

.footer span {
  font-weight: 700;
  color: yellowgreen;
}

.container {
  min-height: 100vh;
  padding: 0 0.5rem 200px 0.5rem;
  display: flex;
  flex-direction: column;
}


@media (max-width: 600px) {
  .container {
    width: 100%;
    flex-direction: column;
  }
}